<template> 
    <div class="server-info">
        <h2>Percentual Usado:</h2>
        <div 
            class="bar percentual"
            :style = "{width: percentual_uso + '%'}" 
        > 
            {{ percentual_uso }}%
        </div>
        <h2> Espaço em Disco: </h2>
        <div 
            class="bar total" 
            :style="{width: 100 + '%'}"
        >
            {{ espaco_total }}GB TOTAL
        </div> 
        
        <div class="livre-usado">
            <div 
                class="bar livre" 
                :style="{width: ((espaco_livre / espaco_total) * 100) + '%'}"
            > 
                {{ espaco_livre }}GB LIVRE
            </div>

            <div 
                class="bar usado" 
                :style = "{width: ((espaco_usado / espaco_total) * 100) + '%'}"
            > 
                {{ espaco_usado }}GB USADO
            </div>
        </div>
    </div>
</template>
    
<script>
    export default{
        name: "ServidorInfoDisplay",
        props: {
            espaco_total: Number,
            espaco_livre: Number,
            espaco_usado: Number,
            percentual_uso: Number,
        },
    };
</script>

<style scoped>
    .server-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 180px;
        }
    .bar{
    font-size: 15px;
    padding: 5px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    color: #000;
    }

    .livre-usado{
        display: flex;
    }

    .livre {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #AFF213;
    }
    .usado {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: #E61E50;
    }
    .total {
        background-color: #48CAF4;

    }
    .percentual {
        background-color: #FC8B14;
    }
</style>